export const ru = {
    newsEnd: 'Новостей больше нет...',
    quickLinks: 'Ссылки',
    contacts: 'Контакты',
    socialMedias: 'Социальные сети',
    mainNews: 'Основные новости',
    rubrics: 'Рубрики',
    siteCreatedBy: 'Сайт был создан: ',
    projectTarget: 'Цель проекта:',
    targetText: 'Внедрение в общество подростков и молодых людей с особыми способностями, пропаганда и стимулирование их интеллектуальной деятельности в современную эпоху бурного развития новых технологий.',
    companyText: 'Сайт разработан при финансовой поддержке Совет государственной поддержки неправительственных организаций. Мнения и соображения, отраженные на сайте, принадлежат автору и не отражают официальную позицию Совет государственной поддержки неправительственных организаций.',
	chevik: 'Проект Chevik.az © Все права защищены'
}