import React from 'react';
import InfiniteNews from './../../components/InfiniteNews';

const AllNews = () => {
    return (
        <div>
            <InfiniteNews />
        </div>
    );
};

export default AllNews;